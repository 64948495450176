import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.scss'
import {BrowserRouter} from "react-router-dom";
import {ReactFlowProvider} from "reactflow";
import {Provider as AuthProvider} from "./components/auth.context";
// @ts-ignore
import {KeycloakConfig} from "keycloak-js"
import {Provider} from "react-redux";
import {persistor, store} from "./redux/store";
import {PersistGate} from "redux-persist/integration/react";



const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const authConfig: KeycloakConfig = {
    url: process.env.REACT_APP_KEYCLOAK_URL || "https://auth.apps.eo4eu.eu/auth",
    realm: process.env.REACT_APP_KEYCLOAK_REALM || "EO4EU",
    clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID || "dsl_editor",
}

root.render(
    <AuthProvider keycloakConf={authConfig}>
        <Provider store={store}>
            <PersistGate persistor={persistor}>
                <BrowserRouter>
                    <ReactFlowProvider>
                        <App/>
                    </ReactFlowProvider>
                </BrowserRouter>
            </PersistGate>
        </Provider>
    </AuthProvider>

);
